import { ReactLazyPreload } from "../helpers/routes";
import { SkuTablePage } from "../pages/sku/skuTablePage";

const MainPageLazy = ReactLazyPreload(() =>
  import("../pages/mainPage").then((m) => ({
    default: m.MainPage,
  })),
);
const StorePageLazy = ReactLazyPreload(() =>
  import("../pages/store/storePage").then((m) => ({
    default: m.StorePage,
  })),
);
const ExtensionPageLazy = ReactLazyPreload(() =>
  import("../pages/extensionPage").then((m) => ({
    default: m.ExtensionPage,
  })),
);
const SkusPageLazy = ReactLazyPreload(() =>
  import("../pages/sku/skus").then((m) => ({
    default: m.SkusPage,
  })),
);
const SkuPageLazy = ReactLazyPreload(() =>
  import("../pages/sku/sku").then((m) => ({
    default: m.SkuPage,
  })),
);
const SkuTablePageLazy = ReactLazyPreload(() =>
  import("../pages/sku/skuTablePage").then((m) => ({
    default: m.SkuTablePage,
  })),
);
const SkuCreatePageLazy = ReactLazyPreload(() =>
  import("../pages/sku/create").then((m) => ({
    default: m.CreateUpdateSku,
  })),
);
const StockPageLazy = ReactLazyPreload(() =>
  import("../pages/stock/stockPage").then((m) => ({
    default: m.StockPage,
  })),
);
const PricesPageLazy = ReactLazyPreload(() =>
  import("../pages/prices/pricesPage").then((m) => ({
    default: m.PricesPage,
  })),
);
const CompaniesPageLazy = ReactLazyPreload(() =>
  import("../pages/companies/companiesPage").then((m) => ({
    default: m.CompaniesPage,
  })),
);
const CreateCompanyPageLazy = ReactLazyPreload(() =>
  import("../pages/companies/createPage").then((m) => ({
    default: m.CreateCompanyPage,
  })),
);
const Page404Lazy = ReactLazyPreload(() =>
  import("../pages/404page").then((m) => ({
    default: m.Page404,
  })),
);
const LoginPageLazy = ReactLazyPreload(() =>
  import("../pages/loginPage").then((m) => ({
    default: m.LoginPage,
  })),
);
const SuggestionsPageLazy = ReactLazyPreload(() =>
  import("../pages/suggestions/suggestionsPage").then((m) => ({
    default: m.SuggestionsPage,
  })),
);

export {
  MainPageLazy,
  StorePageLazy,
  ExtensionPageLazy,
  SkusPageLazy,
  SkuPageLazy,
  SkuCreatePageLazy,
  StockPageLazy,
  PricesPageLazy,
  CompaniesPageLazy,
  Page404Lazy,
  CreateCompanyPageLazy,
  LoginPageLazy,
  SuggestionsPageLazy,
  SkuTablePageLazy,
};
