import { createBrowserRouter, RouteObject } from "react-router-dom";
import { MainLayout } from "../layouts/mainLayout";
import { ProtectedRoute } from "../hightOrderComponents/protectedRoute";
import { UnProtectedRoute } from "../hightOrderComponents/unProtectedRoute";
import { Suspense } from "react";
import {
  MainPageLazy,
  SkuCreatePageLazy,
  StorePageLazy,
  ExtensionPageLazy,
  SkuPageLazy,
  SkusPageLazy,
  StockPageLazy,
  PricesPageLazy,
  CompaniesPageLazy,
  Page404Lazy,
  CreateCompanyPageLazy,
  LoginPageLazy,
  SuggestionsPageLazy,
  SkuTablePageLazy,
} from "./imports";

export const routesConfig: RouteObject[] = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <MainPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "stores",
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <StorePageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "prices",
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <PricesPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "chrome-ext",
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <ExtensionPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "stock",
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <StockPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "suggestions",
        element: (
          <ProtectedRoute>
            <Suspense fallback={null}>
              <SuggestionsPageLazy />
            </Suspense>
          </ProtectedRoute>
        ),
      },
      {
        path: "companies",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <CompaniesPageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <CreateCompanyPageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "skus",
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <SkusPageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: "create",
            element: (
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <SkuCreatePageLazy />
                </Suspense>
              </ProtectedRoute>
            ),
          },
          {
            path: "view",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={null}>
                      <SkuPageLazy />
                    </Suspense>
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "table",
            children: [
              {
                path: ":id",
                element: (
                  <ProtectedRoute>
                    <Suspense fallback={null}>
                      <SkuTablePageLazy />
                    </Suspense>
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: ":id",
            element: (
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <SkuCreatePageLazy isEdit />
                </Suspense>
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: (
      <UnProtectedRoute>
        <Suspense fallback={null}>
          <LoginPageLazy />
        </Suspense>
      </UnProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <Page404Lazy />,
  },
];

export const router = createBrowserRouter(routesConfig);
