import { FC, PropsWithChildren } from "react";
import { useReactiveVar } from "@apollo/client";
import { userStore } from "../store/user.store";
import { Navigate } from "react-router-dom";
import ErrorBoundary from "../pages/errorBoundry";

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const user = useReactiveVar(userStore);

  if (!user?.token) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
};
