import { Outlet, ScrollRestoration } from "react-router-dom";
import { Sidebar } from "../components/layouts/sidebar";
import { Workarea } from "../components/layouts/workarea";

import "../styles/components/layouts/mainLayout.css";

export const MainLayout = () => {
  return (
    <div className="main-layout">
      <ScrollRestoration />
      <Sidebar />
      <Workarea>
        <Outlet />
      </Workarea>
    </div>
  );
};
