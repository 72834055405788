import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { GLOBALS } from "../../globals";
import { setContext } from "@apollo/client/link/context";
import { userStore } from "../../store/user.store";
import { onError } from "@apollo/client/link/error";
import { commonLogout } from "../../hooks/useAuth";
import { NetworkError } from "@apollo/client/errors";
import { toast } from "sonner";

type TNetworkError = NetworkError & { statusCode?: number };

const httpLink = createHttpLink({
  uri: ({ operationName }) => {
    return `${GLOBALS.graphqlUrl}?_${operationName}`;
  },
});

const authLink = setContext((_, { headers }) => {
  const token = userStore()?.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const sessionEndHandler = () => {
  commonLogout();
  toast.info("Кажется ваша сессия закончилась. Пожалуйста, войдите снова.");
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(graphQLErrors, networkError);
  if (graphQLErrors) {
    graphQLErrors.forEach((i) => {
      if (
        i.extensions?.code === "AUTH_NOT_AUTHORIZED" ||
        i.extensions?.statusCode === 403
      ) {
        sessionEndHandler();
      } else {
        toast.error(i.message);
      }
    });
  }

  if (networkError) {
    if ((networkError as TNetworkError)?.statusCode === 401) {
      const networkObj = JSON.parse(JSON.stringify(networkError));
      if (networkObj.statusCode === 401) {
        sessionEndHandler();
      }
    }
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
