import { ComponentType, lazy, LazyExoticComponent } from "react";
import { Route, RouteObject } from "react-router-dom";

type LazyExoticComponentWithPreload<T extends ComponentType<any>> =
  LazyExoticComponent<T> & {
    preload: () => Promise<{ default: T }>;
  };

export const ReactLazyPreload = <T extends ComponentType<any>>(
  importStatement: () => Promise<{ default: T }>,
): LazyExoticComponentWithPreload<T> => {
  const Component = lazy(importStatement);
  (Component as LazyExoticComponentWithPreload<T>).preload = importStatement;
  return Component as LazyExoticComponentWithPreload<T>;
};

export const generateRoutes = (routes: RouteObject[]) => {
  return routes.map(({ index, children, lazy, ...rest }, idx) => {
    if (children) {
      return (
        <Route key={idx} {...rest}>
          {generateRoutes(children)}
        </Route>
      );
    }
    return <Route key={idx} index={index} {...rest} />;
  });
};
